import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Sidebar extends Component {
    render() {
        return (
            <div>
                   {/* Main Sidebar Container */}
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <a href="../../index3.html" className="brand-link">
            <img src="../../dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
            <span className="brand-text font-weight-light">Intranet</span>
          </a>
          {/* Sidebar */}
          <div className="sidebar">
            {/* SidebarSearch Form */}
            <div className="form-inline mt-2">
              <div className="input-group" data-widget="sidebar-search">
                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                  <button className="btn btn-sidebar">
                    <i className="fas fa-search fa-fw" />
                  </button>
                </div>
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {/* Add icons to the links using the .nav-icon class
                  with font-awesome or any other icon font library */}
                <li className="nav-item">
                  <a href="#/" className="nav-link">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>
                      Dashboard
              <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/dashboard" className="nav-link"> <i className="far fa-circle nav-icon" />
                        <p>Dashboard v3</p></Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <a href="#/" className="nav-link">
                    <i className="nav-icon fas fa-user" />
                    <p>
                      Karyawan
                      <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">


                      <Link to="/cuti" className="nav-link"> <i className="far fa-circle nav-icon" />
                        <p>Cuti</p>
                      </Link>

                    </li>

                    <li className="nav-item">
                      <Link to="/perdin" className="nav-link"> <i className="far fa-circle nav-icon" />
                        <p>Perjalanan Dinas</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/reimbusment" className="nav-link"> <i className="far fa-circle nav-icon" />
                        <p>Reimbursement</p>
                      </Link>
                    </li>
                    
                  </ul>
                </li>


                <li className="nav-item">
                  <a href="#/" className="nav-link">
                    <i className="nav-icon fas fa-briefcase" />
                    <p>
                      Office Automation
                       <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="/task" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Task</p>
                      </a>
                    </li>

                  </ul>
                </li>

                <li className="nav-item">
                  <a href="#/" className="nav-link">
                    <i className="nav-icon fas fa-hands-helping" />
                    <p>
                      Helpdesk
                       <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="/tiket" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Tiket</p>
                      </a>
                    </li>
                  </ul>
                </li>


                <li className="nav-item">
                  <a href="#/" className="nav-link">
                    <i className="nav-icon fas fa-tools" />
                    <p>
                      Administrator
                       <i className="fas fa-angle-left right" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="/user" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>User</p>
                      </a>
                    </li>
                  </ul>
                </li>


              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>
            </div>
        )
    }
}
