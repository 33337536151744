import React, { Component } from 'react'
import { Fragment } from 'react'

export default class LinkTopBar extends Component {
    render() {
        return (
            <Fragment>
                <li className="nav-item">
                    <a href="#/" className="nav-link" data-widget="pushmenu"  role="button"><i className="fas fa-bars" /></a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <a href="../../index3.html" className="nav-link">Home</a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <a href="/#" className="nav-link">Contact</a>
                </li>
            </Fragment>
        )
    }
}
