import React, { Component } from 'react'
import NotificationTopBar from './NotificationTopBar';
import UserTopBar from './UserTopBar';
import TopbarMessage from './message';
import LinkTopBar from './LinkTopBar';

export default class NavbarTopBar extends Component {
    render() {
        return (
            <div>
                {/* Navbar */}
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <LinkTopBar />
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        
                        <TopbarMessage />
                        <NotificationTopBar />
                        <UserTopBar />

                        <li className="nav-item">
                            <a  className="nav-link" data-widget="fullscreen" href="#/" role="button">
                                <i className="fas fa-expand-arrows-alt" />
                            </a>
                        </li>

                    </ul>
                </nav>
                {/* /.navbar */}
            </div>
        )
    }
}
