import React, { Component } from 'react'

import Footer from '../../templates/Footer'
import Header from '../../templates/Header'
import $ from 'jquery';


import { connect } from 'react-redux';
import ServiceAPI from '../../../service/ServiceApi';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import HeaderBreadcrumb from '../../../component/atoms/HeaderBreadcrumb';
import ReimbInput from './reimbInput';
import ReimbData from './reimbData';


const MySwal = withReactContent(Swal)

class Reimbursement extends Component {
    state = {
        id: '',
        dateRequest: '',
        name: '',
        type: '',
        treatment: '',
        cost: 0,
        data: []
    }


    constructor(props) {
        super(props);
        // buat ref untuk menyimpan elemen DOM textInput
        this.datefrom = React.createRef();
        this.dateto = React.createRef();
        this.note = React.createRef();
    }

    componentDidMount() {
        this.getData();
        this.$el = $(this.el);

        // this.$el.daterangepicker();
        // this.$el.focus(function(){
        //     alert("test");
        //   });

        //$('#reservation').daterangepicker()
        // window.$('#reservationdate').datetimepicker({
        //     format: 'L'
        // });
        // window.$('#reservationtodate').datetimepicker({
        //     format: 'L'
        // });





    }




    getData = () => {
        // console.log('get-profile');
        ServiceAPI.getReimb()
            .then((result) => {
                this.setState(
                    {
                        data: result
                    },
                    function () {
                        // console.log('data', this.state.data);
                    }
                )
                //console.log(result);
            });
    }

    handleDelete = (data, e) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Data Tidak Akan Kembali!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus!'
        }).then((result) => {
            if (result.isConfirmed) {
                const dataPost = { id: data }
                ServiceAPI.deleteReimb(dataPost)
                    .then((result) => {
                        this.getData();
                        MySwal.fire({
                            icon: 'success',
                            toast: true,
                            title: <p>Data Berhasil Dihapus</p>,
                            position: 'top-end',
                            timer: 3000,
                            showConfirmButton: false
                        });
                    })
                    .catch(
                        (err) => {
                            console.log('error', err)
                        }
                    )
                //     Swal.fire(
                //     'Deleted!',
                //     'Your file has been deleted.',
                //     'success'
                //   )

            }
        });


    }

    handleUpdate = (data) => {

        this.setState({
            id: data.id,
            dateRequest: data.requestdate,
            name:  data.name,
            type:  data.medicalcode,
            treatment:  data.disease,
            cost:  data.cost,
        },
            function () {

                //     console.log('state',this.state);
                // this.datefrom.current.value = this.state.startdate;
                // this.dateto.current.value = this.state.finishdate;
                // this.note.current.value = this.state.note;

            }
        )
    }

    handleClearState = () => {
        this.setState({
            id: '',
            dateRequest: '',
            name: '',
            type: '',
            treatment: '',
            cost: 0,
        })
    }

    render() {
        return (
            <div>
                <Header />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <HeaderBreadcrumb title="Medical Reimbusment" breadcrumbp="Home" breadcrumbc="Medical Reimbusment" />
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {/* /.bagian kiri */}
                                <div className="col-md-4">
                                    <ReimbInput getData={this.getData} clearState={this.handleClearState}  dataid={this.state.id} dateRequest={this.state.dateRequest} name={this.state.name}  type={this.state.type} treatment={this.state.treatment}  cost={this.state.cost}   />
                                </div>

                                {/* /.bagian kanan */}
                                <div className="col-md-8">
                                    {/* /.form cuti */}
                                    <ReimbData data={this.state.data} handleDelete={(data, e) => this.handleDelete(data, e)} handleUpdate={(data) => this.handleUpdate(data)} />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <Footer />
            </div>
        )
    }
}


const reduxState = (state) => ({
    isLoading: state.isLoading,
})


const reduxDispatch = (dispatch) => ({
    changeLoading: (data) => dispatch({ type: 'CHANGE_LOADING', value: data }),
})


export default connect(reduxState, reduxDispatch)(Reimbursement)