import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ServiceAPI from '../../../service/ServiceApi';
import Button from '../../../component/atoms';
import {
    DatePicker
} from "react-tempusdominus-bootstrap";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const ProfileFamily = () => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const datagender = { 0: 'Perempuan', 1: 'Laki Laki'};
    const datarelation={0:'Bapak',1:'Ibu',2:'Istri',3:'Suami',4:'Anak',5:'Saudara Kandung',6:'Lainnya'};    
    const [datafamily, setDataFamily] = useState([]);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [relation, setRelation] = useState('');
    const [birthday, setBirthday] = useState('');
    const [education, setEducation] = useState('');
    const [work, setWork] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [note, setNote] = useState('');
    const getFamilyData = () => {
        // console.log('get-profile');
        ServiceAPI.getFamily()
            .then((result) => {
                console.log(result);
                setDataFamily(result)
            })
            ;
    }

    const handleUpdate = (data) => 
    {
        setId(data.id);
        setName(data.name);
        setGender(data.gender);
        setRelation(data.relation);
        setBirthday(data.birthday);
        setEducation(data.educationcode);
        setWork(data.job);
        setCompany(data.company);
        setPosition(data.position);
        setNote(data.note);
    }
   const  handleDelete = (data, e) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Data Tidak Akan Kembali!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus!'
        }).then((result) => {
            if (result.isConfirmed) {
                const dataPost = { id: data }
                ServiceAPI.deleteFamily(dataPost)
                    .then((result) => {
                        getFamilyData();
                        MySwal.fire({
                            icon: 'success',
                            toast: true,
                            title: <p>Data Berhasil Dihapus</p>,
                            position: 'top-end',
                            timer: 3000,
                            showConfirmButton: false
                        });
                    })
                    .catch(
                        (err) => {
                            console.log('error', err)
                        }
                    )
                //     Swal.fire(
                //     'Deleted!',
                //     'Your file has been deleted.',
                //     'success'
                //   )

            }
        });


    }
    const clearState = () =>
    {
        setId('');
        setName('');
        setGender('');
        setRelation('');
        setBirthday('');
        setEducation('');
        setWork('');
        setCompany('');
        setPosition('');
        setNote('');
    }

    const postData = (e) => {

        dispatch({ type: 'CHANGE_LOADING', value: true })
        const data = {id:id, name: name, gender: gender, relation: relation, birthday: birthday,education: education,job: work,company: company,position: position, note: note };
        ServiceAPI.postFamily(data)
            .then((result) => {
                dispatch({ type: 'CHANGE_LOADING', value: false })
                MySwal.fire({
                    icon: 'success',
                    toast: true,
                    title: <p>Data Berhasil Disimpan</p>,
                    position: 'top-end',
                    timer: 3000,
                    showConfirmButton: false
                });
                getFamilyData();
               // props.clearState();
                clearState();
                e.target.reset();
            })
            .catch(
                (err) => {
                    dispatch({ type: 'CHANGE_LOADING', value: false })
                    console.log('error', err)
                }
            )
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        postData(e);
    }

    useEffect(() => {
        getFamilyData();
    }, []); // Only re-run the effect if count chang

    return (
        <div>
            <form method="post" className="form-horizontal" onSubmit={handleSubmit}>
                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Nama</label>
                    <div className="col-sm-10">
                        <input id="nama" type="text" className="form-control" placeholder="Nama" required autoFocus  onChange={e => setName(e.target.value)} value={name} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Jenis Kelamin</label>
                    <div className="col-sm-10">
                        <select className="form-control" id="gender" placeholder="Jenis Kelamin" value={gender  || ''}  onChange={e => setGender(e.target.value)} >
                            <option value> </option>
                            <option value='1'>Laki Laki</option>
                            <option value='0'>Perempuan</option>
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Relasi</label>
                    <div className="col-sm-10">
                        <select className="form-control" id="relation" placeholder="Relasi" value={relation  || ''}  onChange={e => setRelation(e.target.value)}>
                            <option value> </option>
                            <option value='0'>Bapak</option>
                            <option value='1'>Ibu</option>
                            <option value='2'>Istri</option>
                            <option value='3'>Suami</option>
                            <option value='4'>Anak</option>
                            <option value='5'>Saudara Kandung</option>
                            <option value='6'>Lainnya</option>
                        </select>

                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Tgl. Lahir</label>
                    <div className="col-sm-10">
                        <DatePicker date={birthday} onChange={e => setBirthday(e.date)} locale="id" />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Pendidikan</label>
                    <div className="col-sm-10">
                        <div>
                            <select className="form-control" id="education" placeholder="Pendidikan" value={education || ''}  onChange={e => setEducation(e.target.value)} >
                                <option value> </option>
                                <option value="AK">AK</option>
                                <option value="D1">D1</option>
                                <option value="D2">D2</option>
                                <option value="D3">D3</option>
                                <option value="S1">S1</option>
                                <option value="S2">S2</option>
                                <option value="SD">SD</option>
                                <option value="SMA">SMA</option>
                                <option value="SMP">SMP</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Pekerjaan</label>
                    <div className="col-sm-10">
                        <input id="work" type="text" className="form-control" placeholder="Pekerjaan"  onChange={e => setWork(e.target.value)} value={work || ''} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Perusahaan</label>
                    <div className="col-sm-10">
                        <input id="company" type="text" className="form-control" placeholder="Perusahaan"  onChange={e => setCompany(e.target.value)} value={company || ''} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Posisi</label>
                    <div className="col-sm-10">
                        <input id="position" type="text" className="form-control" placeholder="Posisi"  onChange={e => setPosition(e.target.value)} value={position || ''} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Keterangan</label>
                    <div className="col-sm-10">
                        <textarea id="note" className="form-control" rows={3} placeholder="Enter ..."  onChange={e => setNote(e.target.value)} value={note || ''} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="offset-sm-2 col-sm-10">
                        <Button title="Simpan" />
                    </div>
                </div>
            </form>

            <table className="table">
                <thead>
                    <tr>
                        <th>Nama</th>
                        <th>Jenis Kelamin</th>
                        <th>Relasi</th>
                        <th>Tgl. Lahir</th>
            
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {
                        datafamily.map(data => {
                            return <tr key={data.id}>
                                <td>{data.name}</td>
                                <td>{datagender[data.gender]}</td>
                                <td>{datarelation[data.relation]}</td>
                                <td>{data.birthday}</td>
                                
                                <td className="text-right py-0 align-middle">
                                        <div className="btn-group btn-group-sm">
                                            <a href="#edit" className="btn btn-info" onClick={() => handleUpdate(data)}><i className="fas fa-eye"></i></a>
                                            <a href="#delete" className="btn btn-danger" onClick={(e) => handleDelete(data.id, e)} ><i className="fas fa-trash" ></i></a>
                                        </div>
                                </td>
                            </tr>
                        }
                        )
                    }
                </tbody>
            </table>
        </div>
    );

}

export default ProfileFamily