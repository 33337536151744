// import firebase from '../../firebase';
export const actionUsername = () => (dispatch) => {
    setTimeout(
        ()=>  {
            return dispatch({type:'CHANGE_USER',value:'Reno Gunawan'})
        }
        ,2000)
}

export const registerUserAPI = (data) => (dispatch) =>
{   
    dispatch({type:'CHANGE_LOADING',value:true});
    // return 
    // (
    //     firebase.auth().createUserWithEmailAndPassword(data.email, data.password)
    //         .then((userCredential) => {
    //             dispatch({type:'CHANGE_LOADING',value:false});
    //         })
    //         .catch((error) => {
    //             dispatch({type:'CHANGE_LOADING',value:false});
    //         })
    // )
}