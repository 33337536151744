import React, { Component } from 'react'
import ServiceAPI from '../../../service/ServiceApi';
import Footer from '../../templates/Footer'
import Header from '../../templates/Header'
import ProfileUpdate from './ProfileUpdate';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { connect } from 'react-redux';
import ProfileFamily from './ProfileFamily';

const MySwal = withReactContent(Swal)

class Profile extends Component {
  state = {
    id: '',
    nama: '',
    email: '',
    phone: '',
    address: '',
    note: '',

    id_view: '',
    nama_view: '',
    email_view: '',
    phone_view: '',
    address_view: '',
    note_view: '',
    datafamily: [],
  }

 
  getData = () => {
    // console.log('get-profile');
    ServiceAPI.getProfile()
      .then((result) => {
        this.setState(
          {
            id: result.id,
            employeeid: result.employeeid,
            nama: result.name,
            position: result.positionname,
            email: result.email,
            phone: result.primaryphone,
            address: result.primaryaddress,
            note: result.note,

            id_view: result.id,
            employeeid_view: result.employeeid,
            nama_view: result.name,
            position_view: result.positionname,
            email_view: result.email,
            phone_view: result.primaryphone,
            address_view: result.primaryaddress,
            note_view: result.note
          }
        )
        //console.log(result);
      })
      ;
  }
 

  componentDidMount() {
    this.getData();
  }
  handleChangeText = (element) => {
    //  console.log("handleChange", element.target.value);
    this.setState({
      [element.target.id]: element.target.value,
    });
    //console.log(this.state);
  }
  handleRegisterSubmit = (event) => {
    event.preventDefault();
    this.postData();
  }
  postData = () => {

    
    this.props.changeLoading(true);
    ServiceAPI.postProfile(this.state)
      .then((result) => {
        this.props.changeLoading(false);
        this.setState({
          email_view: this.state.email,
          phone_view: this.state.phone,
          address_view: this.state.address,
          note_view: this.state.note
        });
        MySwal.fire({
          icon: 'success',
          toast: true,
          title: <p>Data Berhasil Disimpan</p>,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false

        });

      })
      .catch(
        (err) => {
          this.props.changeLoading(false);
          console.log('error', err)
        }
      )
  }


  render() {
    return (
      <div>
        <Header />

        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Profile</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#emptyurl">Home</a></li>
                    <li className="breadcrumb-item active">User Profile</li>
                  </ol>
                </div>
              </div>
            </div>{/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  {/* Profile Image */}
                  <div className="card card-primary card-outline">
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <img className="profile-user-img img-fluid img-circle" src="../../dist/img/user4-128x128.jpg" alt="" />
                      </div>

                      <h3 className="profile-username text-center">{this.state.employeeid_view}-{this.state.nama_view}</h3>
                      <p className="text-muted text-center">{this.state.position_view}</p>
                      <ul className="list-group list-group-unbordered mb-3">
                        <li className="list-group-item">
                          <b>Followers</b> <a href="#emptyurl" className="float-right">1,322</a>
                        </li>
                        <li className="list-group-item">
                          <b>Following</b> <a href="#emptyurl" className="float-right">543</a>
                        </li>
                        <li className="list-group-item">
                          <b>Friends</b> <a href="#emptyurl" className="float-right">13,287</a>
                        </li>
                      </ul>
                      <a href="#emptyurl" className="btn btn-primary btn-block"><b>Follow</b></a>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                  {/* About Me Box */}
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">About Me</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <strong><i className="fas fa-map-marker-alt mr-1" /> Alamat</strong>
                      <p className="text-muted">{this.state.address_view}</p>
                      <hr />
                      <strong><i className="fas fa-book mr-1" /> Telepon / HP</strong>
                      <p className="text-muted">
                        {this.state.phone_view}
                      </p>
                      <hr />

                      <strong><i className="fas fa-pencil-alt mr-1" />Email</strong>
                      <p className="text-muted">{this.state.email_view}</p>
                      <hr />
                      <strong><i className="far fa-file-alt mr-1" /> Keterangan</strong>
                      <p className="text-muted">{this.state.note_view}</p>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.col */}
                <div className="col-md-9">
                  <div className="card">
                    <div className="card-header p-2">
                      <ul className="nav nav-pills">
                        <li className="nav-item"><a className="nav-link active" href="#settings" data-toggle="tab">Settings</a></li>
                        <li className="nav-item"><a className="nav-link " href="#family" data-toggle="tab">Keluarga</a></li>
                      </ul>
                    </div>{/* /.card-header */}
                    <div className="card-body">
                      <div className="tab-content">




                        {/* setting input */}
                        <div className="active tab-pane" id="settings">
                         <ProfileUpdate getData={this.getData} />
                        </div>

                        <div className="tab-pane" id="family">

                          <ProfileFamily />

                        </div>
                      </div>
                      {/* /.tab-content */}
                    </div>{/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>{/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
        {/* /.content-wrapper */}

        <Footer />
      </div>
    )
  }
}



const reduxState = (state) => ({
  isLoading: state.isLoading,
})


const reduxDispatch = (dispatch) => ({
  changeLoading: (data) => dispatch({ type: 'CHANGE_LOADING', value: data }),
})


export default connect(reduxState, reduxDispatch)(Profile)
