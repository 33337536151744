import axios from 'axios';
import { getToken, setUserSession } from '../utils/Common';

const domain = process.env.REACT_APP_WEBSERVICE;
//const onlineDomain = process.env.REACT_APP_ONLINE_WEBSERVICE;
//const onlineDomain = 'http://intranetwebservice.rengawan.com';
//const isOnline = process.env.NODE_ENV !== 'production' ? false : true ;
//const isOnline = true;
const BaseUrl = domain;
const BaseUrlApi = `${BaseUrl}/api`;

const Get = (path) => {
    const promise = new Promise(
        (resolve, reject) => {
            //console.log(getToken());
            const AuthStr = 'Bearer '.concat(getToken());

            axios.get(`${BaseUrlApi}/${path}`, { headers: { Authorization: AuthStr } })
                .then(
                    (result) => {
                        resolve(result.data);
                    }
                )
                .catch(
                    (err) => {
                        reject(err);
                    }
                )
        }
    )
    return promise;
}

const Post = (path, data) => {
    const promise = new Promise(
        (resolve, reject) => {
            const AuthStr = 'Bearer '.concat(getToken());
            axios.post(`${BaseUrlApi}/${path}`, data, { headers: { Authorization: AuthStr } })
                .then(
                    (result) => {
                        resolve(result.data);
                    }
                )
                .catch(
                    (err) => {
                        reject(err);
                    }
                )
        }
    )
    return promise;
}


// const Delete = (path, data) => {
//     const promise = new Promise(
//         (resolve, reject) => {
//             const AuthStr = 'Bearer '.concat(getToken());
//             axios.delete(`${BaseUrlApi}/${path}/${data}`, { headers: { Authorization: AuthStr } })
//                 .then(
//                     (result) => {
//                         resolve(result.data);
//                     }
//                 )
//                 .catch(
//                     (err) => {
//                         reject(err);
//                     }
//                 )
//         }
//     )
//     return promise;
// }

const Login = (data) => {
   // console.log(`${BaseUrl}/sanctum/csrf-cookie`);
    const promise = new Promise(
        (resolve, reject) => {
            axios.head(`${BaseUrl}/sanctum/csrf-cookie`, {})
                .then(res => {
                    axios.post(`${BaseUrlApi}/login`, data)
                        .then(res => {
                            setUserSession(res.data.access_token, res.data.user, res.data.profile);
                            resolve(res);    
                        })
                        .catch(error => {
                            reject(error);    
                        })
                })
        }
    )
    return promise;
}

const doLogin = (data) => Login(data);

const getProfile = () => Get('profile');
const postProfile = (data) => Post('profile', data);
const getFamily = () => Get('getfamily');
const postFamily = (data) => Post('postfamily',data);
const deleteFamily = (data) => Post('deletefamily',data);
const getReligion = () => Get('getreligion');



const postCuti = (data) => Post('cuti', data);
const getCuti = () => Get('cuti');
const getCutiInfo = () => Get('informasicuti');
const deleteCuti = (data) => Post('deletecuti',data);

const postTrip = (data) => Post('trip', data);
const getTrip = () => Get('trip');
const deleteTrip = (data) => Post('deletetrip',data);

const postReimb = (data) => Post('reimbusment', data);
const getReimb = () => Get('reimbusment');
const deleteReimb = (data) => Post('deletereimbusment',data);
const getReimbType = () => Get('getreimbusmenttype');

const ServiceAPI = {
    getProfile,
    postProfile,
    getFamily,
    postFamily,
    deleteFamily,
    getReligion,
    doLogin,
    postCuti,
    getCuti,
    getCutiInfo,
    deleteCuti,
    postTrip,
    getTrip,
    deleteTrip,
    postReimb,
    getReimb,
    deleteReimb,
    getReimbType,
}

export default ServiceAPI;
