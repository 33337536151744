import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { getUser, removeUserSession } from '../../../utils/Common';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import { Fragment } from 'react';

export default function UserTopBar() {

    let history = useHistory();
    let profile = getUser();
    let CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href="/#"
            className="nav-link"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}

        </a>
    ));

    function handleLogout() {
        removeUserSession();
        history.push('/login');
    }


    return (
        <Fragment>
                

                
                <Dropdown className="user-menu nav-item" >
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                        <img src="../../dist/img/user2-160x160.jpg" className="user-image img-circle elevation-2" alt=""/>
                        <span className="d-none d-md-inline">{profile.name}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right" className="dropdown-menu-lg" >
                     {/* User image */}
                     <li className="user-header bg-primary">
                            <img src="../../dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="" />
                            <p>
                                {profile.name} - {profile.positionname}
                                <small>Member since Nov. 2012</small>
                            </p>
                        </li>
                        {/* Menu Body */}
                        <li className="user-body">
                            <div className="row">
                                <div className="col-4 text-center">
                                    <a href="#emptyurl">Followers</a>
                                </div>
                                <div className="col-4 text-center">
                                    <a href="#emptyurl">Sales</a>
                                </div>
                                <div className="col-4 text-center">
                                    <a href="#emptyurl">Friends</a>
                                </div>
                            </div>
                            {/* /.row */}
                        </li>
                        {/* Menu Footer*/}
                        <li className="user-footer">

                            <Link to="/profile" className="btn btn-default btn-flat">Profile</Link>
                            <a href="#emptyurl" className="btn btn-default btn-flat float-right" onClick={handleLogout} >Sign out</a>
                        </li>
                    </Dropdown.Menu>   
                    
                  
                </Dropdown>
           
            
               


              

           

        </Fragment>
    )

}
