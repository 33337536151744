import React, { Component } from 'react'
import Footer from '../../templates/Footer'
import Header from '../../templates/Header'
import $ from 'jquery';
import { connect } from 'react-redux';
import ServiceAPI from '../../../service/ServiceApi';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import HeaderBreadcrumb from '../../../component/atoms/HeaderBreadcrumb';
import TripInput from './TripInput';
import TripData from './tripData';



const MySwal = withReactContent(Swal)

class Trip extends Component {
    state = {
        id: '',
        startdate: '',
        finishdate: '',
        destination: '',
        purpose: '',
        cuti: 0,
        cutidiambil: 0,
        cutisisa: 0,
        cutihangus: 0,
        data: []
    }

    constructor(props) {
        super(props);
        // buat ref untuk menyimpan elemen DOM textInput
        this.datefrom = React.createRef();
        this.dateto = React.createRef();
        this.note = React.createRef();
    }

    componentDidMount() {
        this.getData();
        this.$el = $(this.el);

        // this.$el.daterangepicker();
        // this.$el.focus(function(){
        //     alert("test");
        //   });

        //$('#reservation').daterangepicker()
        // window.$('#reservationdate').datetimepicker({
        //     format: 'L'
        // });
        // window.$('#reservationtodate').datetimepicker({
        //     format: 'L'
        // });





    }




    getData = () => {
        // console.log('get-profile');
        ServiceAPI.getTrip()
            .then((result) => {
                this.setState(
                    {
                        data: result
                    },
                    function () {
                        // console.log('data', this.state.data);
                    }
                )
                //console.log(result);
            });
    

    }

    handleDelete = (data, e) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Data Tidak Akan Kembali!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus!'
        }).then((result) => {
            if (result.isConfirmed) {
                const dataPost = { id: data }
                ServiceAPI.deleteTrip(dataPost)
                    .then((result) => {
                        this.getData();
                        MySwal.fire({
                            icon: 'success',
                            toast: true,
                            title: <p>Data Berhasil Dihapus</p>,
                            position: 'top-end',
                            timer: 3000,
                            showConfirmButton: false
                        });
                    })
                    .catch(
                        (err) => {
                            console.log('error', err)
                        }
                    )
                //     Swal.fire(
                //     'Deleted!',
                //     'Your file has been deleted.',
                //     'success'
                //   )

            }
        });


    }

    handleUpdate = (data) => {

        this.setState({
            startdate: data.date_from,
            finishdate: data.date_to,
            purpose: data.purpose,
            destination: data.destination,
            id: data.id,
        },
            function () {

                //     console.log('state',this.state);
                // this.datefrom.current.value = this.state.startdate;
                // this.dateto.current.value = this.state.finishdate;
                // this.note.current.value = this.state.note;

            }
        )
    }

    handleClearState = () => {
        this.setState({
            startdate: '',
            finishdate: '',
            destination: '',
            purpose: '',
            id: '',
        })
    }


    render() {
        return (
            <div>
                <Header />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <HeaderBreadcrumb title="Perjalanan Dinas" breadcrumbp="Home" breadcrumbc="Perjalanan Dinas" />
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {/* /.bagian kiri */}
                                <div className="col-md-4">
                                <TripInput getData={this.getData} clearState={this.handleClearState} startdate={this.state.startdate} finishdate={this.state.finishdate} destination={this.state.destination} purpose={this.state.purpose} dataid={this.state.id}/>
                                </div>
                                
                                {/* /.bagian kanan */}
                                <div className="col-md-8">
                                    {/* /.form cuti */}
                                <TripData data={this.state.data} handleDelete={(data, e) => this.handleDelete(data, e)} handleUpdate={(data) => this.handleUpdate(data)} />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <Footer />
            </div>
        )
    }
}

const reduxState = (state) => ({
    isLoading: state.isLoading,
})


const reduxDispatch = (dispatch) => ({
    changeLoading: (data) => dispatch({ type: 'CHANGE_LOADING', value: data }),
})

export default connect(reduxState, reduxDispatch)(Trip)
