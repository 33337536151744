import React, { Component } from 'react'
import { Dropdown } from 'react-bootstrap'

export default class TopbarMessage extends Component {
    CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href="#/"
            className="nav-link"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        
        </a>
    ));

    render() {
        return (
            <div>
                <li className="nav-item">
                    <Dropdown>
                        <Dropdown.Toggle as={this.CustomToggle} id="dropdown-basic">
                        <i className="far fa-comments" />
                        <span className="badge badge-danger navbar-badge">3</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-lg dropdown-menu-right" >
                            <Dropdown.Item href="#/action-1"> {/* Message Start */}
                                <div className="media">
                                    <img src="../../dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Brad Diesel
                                                <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
                                        </h3>
                                        <p className="text-sm">Call me whenever you can...</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                    </div>
                                </div>
                                
                                {/* Message End */}
                            </Dropdown.Item>
                            <div className="dropdown-divider" />
                            <Dropdown.Item href="#/action-1"> {/* Message Start */}
                                <div className="media">
                                    <img src="../../dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            Brad Diesel
                                                <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
                                        </h3>
                                        <p className="text-sm">Call me whenever you can...</p>
                                        <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                                    </div>
                                </div>
                                
                                {/* Message End */}
                            </Dropdown.Item>
                            <div className="dropdown-divider" />
                            <a href="#/" className="dropdown-item dropdown-footer">See All Messages</a>
                        </Dropdown.Menu>
                    </Dropdown>
                    
                </li>



            </div>
        )
    }
}
