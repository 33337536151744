import React, { Component } from 'react'
import { Dropdown } from 'react-bootstrap'

export default class NotificationTopBar extends Component {
    CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href="#emptyurl"
            className="nav-link"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        
        </a>
    ));

    render() {
        return (
            <div>
                <li className="nav-item">
                <Dropdown>
                <Dropdown.Toggle as={this.CustomToggle} id="dropdown-basic">
                        <i className="far fa-bell" />
                        <span className="badge badge-warning navbar-badge">15</span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-lg dropdown-menu-right" >
                <span className="dropdown-item dropdown-header">15 Notifications</span>
                <div className="dropdown-divider" />
                <Dropdown.Item href="#/action-1"> 
                <i className="fas fa-envelope mr-2" /> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
                </Dropdown.Item>
                <div className="dropdown-divider" />   
                <Dropdown.Item href="#/action-1"> 
                <i className="fas fa-users mr-2" /> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
                </Dropdown.Item>
                <div className="dropdown-divider" />   
                <a href="#emptyurl" className="dropdown-item dropdown-footer">See All Notifications</a>
                </Dropdown.Menu>
                </Dropdown>
                </li>
            </div>
        )
    }
}
