

const ReimbData = (props) => {
    

    return (
        <div className="card card-outline card-primary">
            <div className="card-header">
                <h3 className="card-title">List Medical Reimbustment</h3>
                <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                        <i className="fas fa-minus" />
                    </button>
                </div>
            </div>
            <div className="card-body p-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Tanggal</th>
                            <th>Nama</th>
                            <th>Type</th>
                            <th>Treatment</th>
                            <th>Cost</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.map(data => {
                                return <tr key={data.id}>
                                    <td>{data.requestdate}</td>
                                    <td>{data.name}</td>
                                    <td>{data.medicalcode}</td>
                                    <td>{data.disease}</td>
                                    <td>{data.cost}</td>
                                    <td className="text-right py-0 align-middle">
                                        <div className="btn-group btn-group-sm">
                                            <a href="#edit" className="btn btn-info" onClick={() => props.handleUpdate(data)}><i className="fas fa-eye"></i></a>
                                            <a href="#delete" className="btn btn-danger" onClick={(e) => props.handleDelete(data.id, e)} ><i className="fas fa-trash" ></i></a>
                                        </div>
                                    </td>
                                </tr>
                            }
                            )
                        }
                    </tbody>
                </table>
            </div>
            {/* /.card-body */}
        </div>
    );

}

export default ReimbData