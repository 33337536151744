import React, { Component } from 'react'
import Footer from '../../templates/Footer'
import Header from '../../templates/Header'
import $ from 'jquery';
import { getUser } from '../../../utils/Common';


import { connect } from 'react-redux';
import ServiceAPI from '../../../service/ServiceApi';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CutiInfo from './CutiInfo';
import CutiInput from './CutiInput';
import CutiData from './CutiData';



const MySwal = withReactContent(Swal)

class Cuti extends Component {
    state = {
        id: '',
        startdate: '',
        finishdate: '',
        note: '',
        cuti: 0,
        cutidiambil: 0,
        cutisisa: 0,
        cutihangus: 0,
        data: []
    }

    constructor(props) {
        super(props);
        // buat ref untuk menyimpan elemen DOM textInput
        this.datefrom = React.createRef();
        this.dateto = React.createRef();
        this.note = React.createRef();
    }

    componentDidMount() {
        this.getData();
        this.$el = $(this.el);

        // this.$el.daterangepicker();
        // this.$el.focus(function(){
        //     alert("test");
        //   });

        //$('#reservation').daterangepicker()
        // window.$('#reservationdate').datetimepicker({
        //     format: 'L'
        // });
        // window.$('#reservationtodate').datetimepicker({
        //     format: 'L'
        // });





    }




    getData = () => {
        // console.log('get-profile');
        ServiceAPI.getCuti()
            .then((result) => {
                this.setState(
                    {
                        data: result
                    },
                    function () {
                        // console.log('data', this.state.data);
                    }
                )
                //console.log(result);
            });
        ServiceAPI.getCutiInfo()
            .then((result) => {
                this.setState(
                    {
                        cuti: result.leave,
                        cutisisa: result.leave_remain,
                        cutihangus: result.leave_scorched,
                        cutidiambil: result.leave_taken,
                    },
                    function () {
                        // console.log('data', this.state);
                    }
                )
                //console.log(result.leave);
            });

    }

    handleDelete = (data, e) => {
        Swal.fire({
            title: 'Apakah Anda Yakin?',
            text: "Data Tidak Akan Kembali!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus!'
        }).then((result) => {
            if (result.isConfirmed) {
                const dataPost = { id: data }
                ServiceAPI.deleteCuti(dataPost)
                    .then((result) => {
                        this.getData();
                        MySwal.fire({
                            icon: 'success',
                            toast: true,
                            title: <p>Data Berhasil Dihapus</p>,
                            position: 'top-end',
                            timer: 3000,
                            showConfirmButton: false
                        });
                    })
                    .catch(
                        (err) => {
                            console.log('error', err)
                        }
                    )
                //     Swal.fire(
                //     'Deleted!',
                //     'Your file has been deleted.',
                //     'success'
                //   )

            }
        });


    }

    handleUpdate = (data) => {

        this.setState({
            startdate: data.date_from,
            finishdate: data.date_to,
            note: data.reason,
            id: data.id,
        },
            function () {

                //     console.log('state',this.state);
                // this.datefrom.current.value = this.state.startdate;
                // this.dateto.current.value = this.state.finishdate;
                // this.note.current.value = this.state.note;

            }
        )
    }

    handleClearState = () => {
        this.setState({
            startdate: '',
            finishdate: '',
            note: '',
            id: '',
        })
    }


    render() {
        const profile = getUser();


        //console.log('Profile',profile);

        return (
            <div>
                <Header />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Cuti</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#dashboard">Home</a></li>
                                        <li className="breadcrumb-item active">Cuti</li>
                                    </ol>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {/* /.bagian kiri */}
                                <CutiInfo name={profile.name} positionname={profile.positionname} sisacuti_tl={this.state.cutisisa} hakcuti={this.state.cuti} cutihangus={this.state.cutihangus} cutidiambil={this.state.cutidiambil} />
                                {/* /.bagian kanan */}
                                <div className="col-md-8">
                                    {/* /.form cuti */}
                                    <CutiInput getData={this.getData} clearState={this.handleClearState} startdate={this.state.startdate} finishdate={this.state.finishdate} note={this.state.note} dataid={this.state.id} />
                                    <CutiData data={this.state.data} handleDelete={(data, e) => this.handleDelete(data, e)} handleUpdate={(data) => this.handleUpdate(data)} />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <Footer />
            </div>
        )
    }
}

const reduxState = (state) => ({
    isLoading: state.isLoading,
})


const reduxDispatch = (dispatch) => ({
    changeLoading: (data) => dispatch({ type: 'CHANGE_LOADING', value: data }),
})


export default connect(reduxState, reduxDispatch)(Cuti)
