import React from 'react';

const Button = ({title,onClick,loading}) => {
    if(loading)
    {
        return <button type="submit" className="btn  btn-primary btn-block disabled" >Loading ...</button>
    }
    
    return <button type="submit" className="btn  btn-primary btn-block" onClick={onClick} >{title}</button>
}
export default Button;