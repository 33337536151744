import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ServiceAPI from '../../../service/ServiceApi';
import Button from '../../../component/atoms';
import {
  DatePicker
} from "react-tempusdominus-bootstrap";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ProfileUpdate = (props) => {
  const isLoading = useSelector(state => state.isLoading)

  const [id, setId] = useState('');
  const [employeeid, setemployeeid] = useState('');
  const [name, setNama] = useState('');
  const [nickname, setNickname] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [primaryphone, setPhone] = useState('');
  const [primaryaddress, setAddress] = useState('');
  const [note, setNote] = useState('');
  const [primarycity, setPrimaryCity] = useState('');
  const [primaryzip, setPrimaryZip] = useState('');
  const [emergencycontact, setEmergencyContact] = useState('');
  const [emergencyphone, setEmergencyPhone] = useState('');
  const [emergencyaddress, setEmergencyAddress] = useState('');
  const [birthplace, setBirthplace] = useState('');
  const [birthday, setBirthday] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [bloodtype, setBloodType] = useState('');

  const [idcard, setIdCard] = useState('');
  const [driverlicensea, setDriverLicenseA] = useState('');
  const [driverlicenseb, setDriverLicenseB] = useState('');
  const [driverlicensec, setDriverLicenseC] = useState('');

  const [nationality, setNationality] = useState('');
  const [passport, setPassport] = useState('');
  const [religion, setReligion] = useState('');
  const [datareligion, setDataReligion] = useState([]);
  const [educationlevel, setEducationLevel] = useState('');
  const [maritalstatus, setMaritalStatus] = useState('');
  const [familystatus, setFamilyStatus] = useState('');
  const [weddingdate, setWeddingDate] = useState('');




  const dispatch = useDispatch()
  const MySwal = withReactContent(Swal)

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    postData(e);
  }

  const getReligionData = () => {
    // console.log('get-profile');
    ServiceAPI.getReligion()
      .then((result) => {
        setDataReligion(result);
      })
      ;
  }

  const getData = () => {
    // console.log('get-profile');
    ServiceAPI.getProfile()
      .then((result) => {
        setId(result.id);
        setemployeeid(result.employeeid);
        setNickname(result.nickname);
        setGender(result.gender);
        setNama(result.name);
        setEmail(result.email);
        setPhone(result.primaryphone);
        setAddress(result.primaryaddress);
        setNote(result.note);
        setPrimaryCity(result.primarycity);
        setPrimaryZip(result.primaryzip);
        setEmergencyContact(result.emergencycontact);
        setEmergencyPhone(result.emergencyphone);
        setEmergencyAddress(result.emergencyaddress);
        setBirthplace(result.birthplace);
        setBirthday(result.birthday);
        setBirthplace(result.birthplace);
        setWeight(result.weight);
        setHeight(result.height);
        setBloodType(result.bloodtype);
        setIdCard(result.idcard);
        setDriverLicenseA(result.driverlicensea);
        setDriverLicenseB(result.driverlicenseb);
        setDriverLicenseC(result.driverlicensec);
        setNationality(result.nationality)
        setPassport(result.passport)
        
        setReligion(result.religion);
        setEducationLevel(result.educationlevel);
        setMaritalStatus(result.maritalstatus);
        setFamilyStatus(result.familystatus);
        setWeddingDate(result.weddingdate);

      })
      ;
  }

  const postData = (e) => {
    dispatch({ type: 'CHANGE_LOADING', value: true })
    //const data = { id, name, email, primaryphone, primaryaddress, note };
    const data = { id,name,nickname,gender,email,primaryphone,primaryaddress,note,primarycity,primaryzip,emergencycontact,emergencyphone,emergencyaddress,birthplace,birthday,weight,height,bloodtype,idcard,driverlicensea,driverlicenseb,driverlicensec,nationality,passport,religion,educationlevel,maritalstatus,familystatus,weddingdate };
    ServiceAPI.postProfile(data)
      .then((result) => {
        dispatch({ type: 'CHANGE_LOADING', value: false })
        // resetForm();
        MySwal.fire({
          icon: 'success',
          toast: true,
          title: <p>Data Berhasil Disimpan</p>,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false
        });
        props.getData();
        //props.clearState();
        //clearState();
        //e.target.reset();
      })
      .catch(
        (err) => {
          dispatch({ type: 'CHANGE_LOADING', value: false })
          console.log('error', err)
        }
      )
  }

  useEffect(() => {
    getReligionData();
    getData();
  }, []); // Only re-run the effect if count chang

  return (
    <form method="post" className="form-horizontal" onSubmit={handleRegisterSubmit}>
      <div className="form-group row">
        <label htmlFor="inputId" className="col-sm-2 col-form-label">ID</label>
        <div className="col-sm-10">
          <input id="nama" type="text" className="form-control" placeholder="ID" required autoFocus value={employeeid} disabled />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputName" className="col-sm-2 col-form-label">Nama</label>
        <div className="col-sm-10">
          <input id="nama" type="text" className="form-control" placeholder="Nama" required autoFocus value={name} disabled />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="inputName" className="col-sm-2 col-form-label">Panggilan</label>
        <div className="col-sm-10">
          <input id="nama" type="text" className="form-control" placeholder="Panggilan" onChange={e => setNickname(e.target.value)} value={nickname} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="inputName" className="col-sm-2 col-form-label">Jenis Kelamin</label>
        <div className="col-sm-10">
          <select className="form-control" id="gender" placeholder="Jenis Kelamin" value={gender} onChange={e => setGender(e.target.value)} >
            <option value> </option>
            <option value='1'>Laki Laki</option>
            <option value='0'>Perempuan</option>
          </select>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputName" className="col-sm-2 col-form-label">Agama</label>
        <div className="col-sm-10">
          <select className="form-control" id="gender" placeholder="Agama" value={religion} onChange={e => setReligion(e.target.value)} >
            <option value> </option>
            {
              datareligion.map(data => {
                return <option value={data.code}>{data.name}</option>
              })
            }


          </select>
        </div>
      </div>


      <div className="form-group row">
        <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
        <div className="col-sm-10">
          <input type="email" className="form-control" id="email" placeholder="Email" value={email} disabled />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="inputPhone" className="col-sm-2 col-form-label">Telepon / HP</label>
        <div className="col-sm-10">
          <input type="text" className="form-control" id="phone" placeholder="Phone" onChange={e => setPhone(e.target.value)} value={primaryphone} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="inputAddress" className="col-sm-2 col-form-label">Alamat</label>
        <div className="col-sm-10">
          <textarea className="form-control" id="address" placeholder="Alamat" onChange={e => setAddress(e.target.value)} value={primaryaddress} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="inputPhone" className="col-sm-2 col-form-label">Kota / Kode Pos</label>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="city" placeholder="Kota" onChange={e => setPrimaryCity(e.target.value)} value={primarycity} />
        </div>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="zip" placeholder="Kode Pos" onChange={e => setPrimaryZip(e.target.value)} value={primaryzip} />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputPhone" className="col-sm-2 col-form-label">Kontak / Phone Darurat</label>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="emergencycontact" placeholder="Kontak Darurat" onChange={e => setEmergencyContact(e.target.value)} value={emergencycontact} />
        </div>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="emergencyphone" placeholder="Kontak Telepon / HP" onChange={e => setEmergencyPhone(e.target.value)} value={emergencyphone} />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputAddress" className="col-sm-2 col-form-label">Alamat Darurat</label>
        <div className="col-sm-10">
          <textarea className="form-control" id="emergencyaddress" placeholder="Alamat" onChange={e => setEmergencyAddress(e.target.value)} value={emergencyaddress} />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputPhone" className="col-sm-2 col-form-label">Kota / Tanggal Lahir</label>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="birthplace" placeholder="Kota" onChange={e => setBirthplace(e.target.value)} value={birthplace} />
        </div>
        <div className="col-sm-5">
          <DatePicker date={birthday} onChange={e => setBirthday(e.date)} locale="id"  />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputWeight" className="col-sm-2 col-form-label">Berat / Tinggi / Golongan Darah</label>
        <div className="col-sm-4">
          <input type="text" className="form-control" id="weight" placeholder="Berat Badan" onChange={e => setWeight(e.target.value)} value={weight} />
        </div>
        <div className="col-sm-4">
          <input type="text" className="form-control" id="height" placeholder="Tinggi Badan" onChange={e => setHeight(e.target.value)} value={height} />
        </div>
        <div className="col-sm-2">
          <input type="text" className="form-control" id="bloodtype" placeholder="Golongan Darah" onChange={e => setBloodType(e.target.value)} value={bloodtype} />
        </div>
      </div>


      <div className="form-group row">
        <label htmlFor="inputIdCard" className="col-sm-2 col-form-label">KTP / SIM A</label>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="idcard" placeholder="KTP" onChange={e => setIdCard(e.target.value)} value={idcard} />
        </div>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="driverlisencea" placeholder="SIM A" onChange={e => setDriverLicenseA(e.target.value)} value={driverlicensea} />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputIdCard" className="col-sm-2 col-form-label">SIM B / SIM C</label>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="driverlisenceb" placeholder="SIM B" onChange={e => setDriverLicenseB(e.target.value)} value={driverlicenseb} />
        </div>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="driverlisencec" placeholder="SIM C" onChange={e => setDriverLicenseC(e.target.value)} value={driverlicensec} />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputNationality" className="col-sm-2 col-form-label">Kebangsaan / Passport</label>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="driverlisenceb" placeholder="Kebangsaan" onChange={e => setNationality(e.target.value)} value={nationality} />
        </div>
        <div className="col-sm-5">
          <input type="text" className="form-control" id="passport" placeholder="Passport" onChange={e => setPassport(e.target.value)} value={passport} />
        </div>
      </div>


      <div className="form-group row">
        <label htmlFor="inputEducation" className="col-sm-2 col-form-label">Pendidikan</label>
        <div className="col-sm-10">
          <select className="form-control" id="education" placeholder="Pendidikan" value={educationlevel} onChange={e => setEducationLevel(e.target.value)} >
            <option value> </option>
            <option value="AK">AK</option>
            <option value="D1">D1</option>
            <option value="D2">D2</option>
            <option value="D3">D3</option>
            <option value="S1">S1</option>
            <option value="S2">S2</option>
            <option value="SD">SD</option>
            <option value="SMA">SMA</option>
            <option value="SMP">SMP</option>
          </select>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputmarital" className="col-sm-2 col-form-label">Status Pernikahan</label>
        <div className="col-sm-4">
          <select className="form-control" id="maritalstatus" placeholder="Status Pernikahan" value={maritalstatus} onChange={e => setMaritalStatus(e.target.value)} >
            <option value> </option>
            <option value='0'>Lajang</option>
            <option value='1'>Menikah</option>
          </select>
        </div>
        <div className="col-sm-4">
          <select className="form-control" id="familystatus" placeholder="Status Keluarga" value={familystatus} onChange={e => setFamilyStatus(e.target.value)} >
            <option value> </option>
            <option value="K0">K0</option>
            <option value="K1">K1</option>
            <option value="K2">K2</option>
            <option value="K3">K3</option>
            <option value="TK">TK</option>
          </select>
        </div>
        <div className="col-sm-2">
          <DatePicker date={weddingdate} onChange={e => setWeddingDate(e.date)} locale="id"  />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputNote" className="col-sm-2 col-form-label">Keterangan</label>
        <div className="col-sm-10">
          <textarea className="form-control" id="note" placeholder="Experience" onChange={e => setNote(e.target.value)} value={note} />
        </div>
      </div>
      <div className="form-group row">
        <div className="offset-sm-2 col-sm-10">
          <Button title="Simpan" loading={isLoading} />
        </div>
      </div>
    </form>
  );

}


export default ProfileUpdate