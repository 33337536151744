import React  from 'react'

import NavbarTopBar from './topbar';
import Sidebar from './sidebar';

export default function Header() {



  return (
    <div>
      <div>
       <NavbarTopBar />
       <Sidebar />
        
      
      </div>

    </div>
  )
}



