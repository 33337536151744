//applyMiddleware untuk redux thunk
import {createStore,applyMiddleware } from 'redux';

//import redux thunk
import thunk from 'redux-thunk';
import reducer from '../reducer'


//buat store
//ditambahkan applyMiddleware thunk
export  const store = createStore(reducer,
    applyMiddleware(thunk)
    );

