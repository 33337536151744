
const CutiInfo = (props) => {
    
    return (
        <div className="col-md-4">
            {/* Widget: user widget style 2 */}
            <div className="card card-widget widget-user-2 shadow-sm">
                {/* Add the bg color to the header using any of the bg-* classes */}
                <div className="widget-user-header bg-primary">
                    <div className="widget-user-image">
                        <img className="img-circle elevation-2" src="../dist/img/user7-128x128.jpg" alt="User Avatar" />
                    </div>
                    {/* /.widget-user-image */}
                    <h3 className="widget-user-username">{props.name}</h3>
                    <h5 className="widget-user-desc">{props.positionname}</h5>
                </div>
                <div className="card-footer p-0">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <a href={() => false} className="nav-link">
                                Sisa Cuti Tahun Lalu <span className="float-right badge bg-primary">{props.sisacuti_tl}</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={() => false} className="nav-link">
                                Hak Cuti <span className="float-right badge bg-info">{props.hakcuti}</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={() => false} className="nav-link">
                                Total Hak Cuti <span className="float-right badge bg-success">{props.sisacuti_tl+props.hakcuti}</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={() => false} className="nav-link">
                                Hak Cuti Hangus <span className="float-right badge bg-danger">{props.cutihangus}</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={() => false} className="nav-link">
                                Hak Cuti Diambil <span className="float-right badge bg-success">{props.cutidiambil}</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={() => false} className="nav-link">
                                Sisa Hak Cuti <span className="float-right badge bg-primary">{(props.sisacuti_tl+props.hakcuti)-props.cutihangus-props.cutidiambil}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* /.widget-user */}



        </div>
    );
}

export default CutiInfo