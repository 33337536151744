import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    DatePicker,
} from "react-tempusdominus-bootstrap";
import Button from '../../../component/atoms';
import ServiceAPI from '../../../service/ServiceApi';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const CutiInput = (props) => {
    const isLoading = useSelector(state => state.isLoading)
    const [id, setId] = useState();
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [note, setNote] = useState();
    const dispatch = useDispatch()
    const MySwal = withReactContent(Swal)

    const clearState =() => {
        setNote('');
        setDateFrom('');
        setDateTo('');
        setId('');
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        postData(e);
    }

    
    
    const postData = (e) => {

        dispatch({ type: 'CHANGE_LOADING', value: true })
        const data = {id:id, startdate: dateFrom, finishdate: dateTo, note: note };
        ServiceAPI.postCuti(data)
            .then((result) => {
                dispatch({ type: 'CHANGE_LOADING', value: false })
                // resetForm();
                MySwal.fire({
                    icon: 'success',
                    toast: true,
                    title: <p>Data Berhasil Disimpan</p>,
                    position: 'top-end',
                    timer: 3000,
                    showConfirmButton: false
                });
                props.getData();
                props.clearState();
                clearState();
                e.target.reset();
            })
            .catch(
                (err) => {
                    dispatch({ type: 'CHANGE_LOADING', value: false })
                    console.log('error', err)
                }
            )
    }
    useEffect(() => {
        if (props.dataid!=='')
        {
            setId(props.dataid)
            setDateFrom(props.startdate);
            setDateTo(props.finishdate);
            setNote(props.note);
        }
        //console.log(process.env.REACT_APP_ONLINE_WEBSERVICE);
        
        // setDateFrom(props.startdate);
      }, [props.dataid,props.startdate,props.finishdate,props.note]);

    return (
        <form method="post" className="form-horizontal" onSubmit={handleSubmit}>
            <div className="card card-primary ">

                <div className="card-header">
                    <h3 className="card-title">Pengajuan Cuti</h3>
                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse" ><i className="fas fa-plus" />
                        </button>
                    </div>
                    {/* /.card-tools */}
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <div className="form-group">
                        <label>Tanggal Mulai</label>
                        <DatePicker date={dateFrom} onChange={e => setDateFrom(e.date)} locale="id" onUpdate={e => {
                            console.log(
                                "onUpdate",
                                e.change,
                                e.viewDate,
                                e.viewDate.format("YYYY-MM-DD HH:mm:ss"),
                                e
                            );
                        }}   />
                        
                        {/* /.input group */}
                    </div>
                    <div className="form-group">
                        <label>Tanggal Selesai</label>
                        <DatePicker date={dateTo} onChange={e => setDateTo(e.date)} locale="id" />
                        {/* /.input group */}
                    </div>

                    <div className="form-group">
                        <label>Keterangan</label>
                        <div className="input-group">
                            <textarea id="note" className="form-control" rows={3} placeholder="Enter ..." required onChange={e => setNote(e.target.value)} value={note} />
                        </div>

                    </div>
                </div>
                <div className="card-footer">
                    <Button title="Simpan" loading={isLoading} />
                </div>


                {/* /.card-body */}
            </div>
        </form>

    );

}

export default CutiInput