import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { registerUserAPI } from '../../../config/redux/action';
import Button from '../../../component/atoms';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import ServiceAPI from '../../../service/ServiceApi';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const BODY_LOGIN_CLASS = "login-page";

class Login extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        email: '',
        password: ''

    }
    handleChangeText = (element) => {
        //  console.log("handleChange", element.target.value);
        this.setState({
            [element.target.id]: element.target.value,
        })
    }


    handleRegisterSubmit = (event) => {
        // const { email, password } = this.state;
        // const { match, location, history } = this.props;
     

        this.props.changeLoading(true);
        ServiceAPI.doLogin({email: this.state.email, password: this.state.password})
            .then((result) => {
                this.props.changeLoading(false);
                window.location.reload();
                //history.replace('/dashboard');
                //console.log(result);
            })
            .catch(error => {
                // var errorCode = error.code;
                // var errorMessage = error.message;
                this.props.changeLoading(false);
                MySwal.fire({
                    icon: 'warning',
                    title: <p>Invalid Login Credentials</p>,
                    position: 'center',
                    timer: 1000,
                    showConfirmButton: false
                   
                  }) ;
                // console.log('error',error);
            })
            ;

        // axios.head(`http://localhost:8000/sanctum/csrf-cookie`, {})
        //     .then(res => {
        //         axios.post(`http://localhost:8000/api/login`, { email: this.state.email, password: this.state.password })
        //             .then(res => {
        //                 console.log(res);
        //                 //console.log(res.data);
        //                 //this.props.changeLogin(true);
        //                 setUserSession(res.data.access_token, res.data.user, res.data.profile);
        //                 history.replace('/dashboard');

        //             })
        //             .catch(error => {
        //                 var errorCode = error.code;
        //                 var errorMessage = error.message;
        //                 //console.log('error',error);
        //                 // if(error.response.status==401){
        //                 //     //redirect to login
        //                 //     console.log('kunyuk');
        //                 //   }
        //                 // ..
        //             })
        //     })




        event.preventDefault();

    }

    componentDidMount() {
        document.body.classList.add(BODY_LOGIN_CLASS);
        
        console.log('props', this.props);
      //  console.log('token', getToken());
    }
    componentWillUnmount() {
        document.body.classList.remove(BODY_LOGIN_CLASS);
    }
    render() {

        return (
            <Fragment>

                <div className="login-box">
                    <div className="login-logo">
                        <b>INTRANET</b>
                    </div>
                    {/* /.login-logo */}
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Sign in to start your session</p>
                            <form action="" method="post" onSubmit={this.handleRegisterSubmit} >
                                <div className="input-group mb-3">
                                    <input id="email" type="email" className="form-control" placeholder="Email" required autoFocus onChange={this.handleChangeText} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input id="password" type="password" className="form-control" placeholder="Password" required onChange={this.handleChangeText} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">
                                                Remember Me
              </label>
                                        </div>
                                    </div>
                                    {/* /.col */}
                                    <div className="col-4">
                                        
                                        <Button title="Sign In" loading={this.props.isLoading} />

                                    </div>
                                    {/* /.col */}
                                </div>
                            </form>
                            <div className="social-auth-links text-center mb-3">
                                <p>- OR -</p>
                                <a href="#emptyurl" className="btn btn-block btn-primary">
                                    <i className="fab fa-facebook mr-2" /> Sign in using Facebook
        </a>
                                <a href="#emptyurl" className="btn btn-block btn-danger">
                                    <i className="fab fa-google-plus mr-2" /> Sign in using Google+
        </a>
                            </div>
                            {/* /.social-auth-links */}
                            <p className="mb-1">
                                <a href="forgot-password.html">I forgot my password</a>
                            </p>

                        </div>
                        {/* /.login-card-body */}
                    </div>
                </div>
            </Fragment>

        )
    }
}


const reduxState = (state) => ({
    isLoading: state.isLoading,
    isLogin: state.isLogin
})


const reduxDispatch = (dispatch) => ({
    registerAPI: (data) => dispatch(
        registerUserAPI(data)
    ),
    changeLogin: (data) => dispatch({ type: 'CHANGE_ISLOGIN', value: data }),
    changeLoading: (data) => dispatch({ type: 'CHANGE_LOADING', value: data }),
})

//export default connect(reduxState, reduxDispatch)(Login);

export default withRouter(connect(reduxState, reduxDispatch)(Login))