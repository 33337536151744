import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    DatePicker,
} from "react-tempusdominus-bootstrap";
import Button from '../../../component/atoms';
import ServiceAPI from '../../../service/ServiceApi';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { getUser } from '../../../utils/Common';
const ReimbInput = (props) => {
    const isLoading = useSelector(state => state.isLoading)
    const [id, setId] = useState();
    const [dateRequest, setDateRequest] = useState();
    const [name, setName] = useState('');

    const [typecode, setTypeCode] = useState('');
    const [treatment, setTreatment] = useState();
    const [cost, setCost] = useState(0);
    const [datafamily, setDataFamily] = useState([]);
    const [datatype, setDataType] = useState([]);
    const dispatch = useDispatch()
    const MySwal = withReactContent(Swal)
    let profile = getUser();
    

    const clearState =() => {
        setId('');
        setDateRequest('');
        setName('');
        setTypeCode('');
        setTreatment('');
        setCost('');
    }
    


    const getFamilyData = () => {

        ServiceAPI.getFamily()
            .then((result) => {
                setDataFamily(result);
            })
            ;
    }
    const getTypeData = () => {

        ServiceAPI.getReimbType()
            .then((result) => {
                setDataType(result);
            })
            ;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        postData(e);
    }



    const postData = (e) => {

        dispatch({ type: 'CHANGE_LOADING', value: true })
        const data = {id, dateRequest,name,type:typecode,treatment,cost };
        ServiceAPI.postReimb(data)
            .then((result) => {
                dispatch({ type: 'CHANGE_LOADING', value: false })
                // resetForm();
                MySwal.fire({
                    icon: 'success',
                    toast: true,
                    title: <p>Data Berhasil Disimpan</p>,
                    position: 'top-end',
                    timer: 3000,
                    showConfirmButton: false
                });
                props.getData();
                props.clearState();
                clearState();
                e.target.reset();
            })
            .catch(
                (err) => {
                    dispatch({ type: 'CHANGE_LOADING', value: false })
                    console.log('error', err)
                }
            )
    }


    useEffect(() => {
        if (props.dataid!=='')
        {
            setId(props.dataid)
            setDateRequest(props.dateRequest);
            setTypeCode(props.type);
            setName(props.name);
            
            setTreatment(props.treatment);
            setCost(props.cost);
           
        }
       // console.log(props);
        // setDateFrom(props.startdate);
      }, [props.dataid,props.dateRequest,props.type,props.name,props.treatment,props.cost]);

    useEffect(() => {
        getFamilyData();
        getTypeData();
    }, []); // Only re-run the effect if count chang

    return (
        <form method="post" className="form-horizontal" onSubmit={handleSubmit}>
            <div className="card card-primary ">

                <div className="card-header">
                    <h3 className="card-title">Form Pengajuan</h3>
                    <div className="card-tools">
                        <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                            <i className="fas fa-minus" />
                        </button>
                    </div>
                    {/* /.card-tools */}
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <div className="form-group">
                        <label>Tanggal</label>
                        <DatePicker date={dateRequest} onChange={e => setDateRequest(e.date)} locale="id" />
                        {/* /.input group */}
                    </div>
                    
                    <div className="form-group">
                        <label>Nama</label>
                        <div className="input-group">
                            <select className="form-control" id="name" placeholder="Nama" value={name} onChange={e => setName(e.target.value)}  required>
                                <option value="" >Silahkan Pilih Nama</option>
                                <option value={profile.name} key={profile.name}>{profile.name}</option>
                                {
                                    datafamily.map(data => {
                                        return <option value={data.name} key={data.name}>{data.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Tipe</label>
                        <div className="input-group">
                            <select className="form-control" id="type" placeholder="Tipe" value={typecode} onChange={e => setTypeCode(e.target.value)} required>
                                <option value="" >Silahkan Pilih Type</option>
                                {
                                    datatype.map(data => {
                                        return <option value={data.code} key={data.code}>{data.code}  {data.name}</option>
                                    })
                                }
                            </select>

                        </div>
                    </div>

                    <div className="form-group">
                        <label>Treatment</label>
                        <div className="input-group">
                            <textarea id="treatment" className="form-control" rows={3} placeholder="Silahkan Isi Perawatan" required onChange={e => setTreatment(e.target.value)} value={treatment} />
                        </div>

                    </div>
                    <div className="form-group">
                        <label>Cost</label>
                        <div className="input-group">
                            <input id="cost" type="text" className="form-control" placeholder="Silahkan Isi Biaya" required onChange={e => setCost(e.target.value)} value={cost} />
                        </div>
                    </div>

                  

                    <div className="form-group">
                        <label htmlFor="exampleInputFile">Kwitansi</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="exampleInputFile" />
                                <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card-footer">
                    <Button title="Simpan" loading={isLoading} />
                </div>


                {/* /.card-body */}
            </div>
        </form>

    );
}
export default ReimbInput