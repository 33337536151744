// import logo from '../../../asset/img/logo/logo.svg';
import './App.css';
import Dashboard from '../Dashboard';
import Profile from '../profile/Profile';
import Login from '../login';
import Cuti from '../cuti';
import Trip from '../trip';
import Reimbusment from '../reimbusment';
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from '../../../config/redux';
import PrivateRoute from '../../../utils/PrivateRoute';
import PublicRoute from '../../../utils/PublicRoute';

function App() {
  
  return (
    <Provider store={store}>
      <Router>
      <div className="wrapper">
        <Switch>
          
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/cuti" component={Cuti} />
          <PrivateRoute path="/perdin" component={Trip} />
          <PrivateRoute path="/reimbusment" component={Reimbusment} />
          <PublicRoute  path="/login" component={Login} />
          <PrivateRoute path="/" component={Dashboard} />
          
        </Switch>
      </div>
    </Router>
    </Provider> 
    
  );


}

export default App;

